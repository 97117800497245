.ucr__main {
    flex: 1 1 auto;
    display: grid;
    align-content: start;
    &--stretch {
        display: flex;
        flex-direction: column;
    }
}

.ucr__timeline {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    overflow: hidden;
    position: relative;
    &--split {
        grid-template-rows: 50% 50%;
    }
}

.ucr__error-message {
    color: $danger;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin: 1rem;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 0.25rem;
}
